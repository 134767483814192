import settings from '../settings'
const { locales } = settings

export const defaultState = {
  auth: {
    isAuthenticated: false,
    token: null,
    username: null,
    first_name: null,
  },

  // ────────────────────────────────────────────────────────────────────────────────

  general: {
    isLoadingFields: true,
    errorLoadingFields: false,
    formHasSubmittedSuccessfully: false,
    successRedirectUrl: null,
  },

  // ────────────────────────────────────────────────────────────────────────────────

  mainForm: {
    buttonColor: null,
    buttonText: null,
    description: null,
    emailFields: [], // will be used in future!
    errorMessage: null,
    fields: {},
    logo: null,
    logoPosition: null,
    maxSubmit_count: null,
    needsLogin: false,
    owner: {},
    primaryEmail: null,
    sendEmails: true,
    slug: '',
    submitCount: 0,
    submitEndTime: null,
    submitStartTime: null,
    successMessage: null,
    textColor: null,
    themeColor: null,
    title: '',
    borderColor: null,
    submitTextColor: null,
    showTitle: true,
    type: '',
    showScore: false,
  },

  // ────────────────────────────────────────────────────────────────────────────────

  locale: {
    localeInUse: locales.en,
  },

  // ────────────────────────────────────────────────────────────────────────────────
  generalData: {},

  // ────────────────────────────────────────────────────────────────────────────────

  submitButton: {
    disabled: false,
  },

  aiSyncedAnswers: {
    data: {},
    isAiAnalyzing: false
  },
}

// Answered by AI
