import { combineReducers } from 'redux'

import fieldsValueHistory from './fieldsValueHistory'
import mainForm from './mainForm'
import general from './general'
import aiSyncedAnswers from './aiSyncedAnswers'

const appReducer = combineReducers({
  mainForm,
  general,
  fieldsValueHistory,
  aiSyncedAnswers
})

export default appReducer
