import { WidgetProvider } from 'context/widget.context'
import { Provider } from 'react-redux'
import store from 'stores/store'
import WidgetMapper from './widget-mapper'

export default function App({ type, slug, ...props }) {
  const chatbotScript = document.getElementById('formaloo-chatbot-script')
  const style = chatbotScript.getAttribute('style')
  const dir = chatbotScript.getAttribute('dir')

  return (
    <div id="formaloo-chatbot-container" style={style} dir={dir}>
      <div
        style={{
          fontFamily:
            '-apple-system, BlinkMacSystemFont, ff-mark-pro, sans-serif',
        }}
      >
        <WidgetProvider
          formSlug={slug}
          widgetProps={{ ...props, widgetType: type }}
        >
          <Provider store={store}>
            <WidgetMapper />
          </Provider>
        </WidgetProvider>
      </div>
    </div>
  )
}
