export default function aiSyncedAnswers(
  state = {
    data: {},
    isAiAnalyzing: false,
  },
  action,
) {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isAiAnalyzing: action.isAiAnalyzing }
    case 'UPDATE_DATA':
      return {
        isAiAnalyzing: state.isAiAnalyzing,
        data: { ...state.data, ...action.data },
      }
    default:
      return state
  }
}
