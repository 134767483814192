import { config } from './utils'
import API from 'settings/api.settings'
import { httpClient } from './utils/http'

export const formalooAI = () => ({
  analyze: (data, params = {}) =>
    httpClient.post(
      `${API.aiApi}/v1/choice-analyzes/`,
      data,
      config({ params, disableApiKey: true }),
    ),
})
