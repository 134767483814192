import React from 'react'
import equals from 'ramda/src/equals'
import { setGeneralDataToSubmit } from '../actions/actionCreators'
import { useInterval } from '../hooks/useInterval'
import useMountEffect from '../hooks/useMountEffect'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'preact/hooks'
import { useWatch } from 'react-hook-form'

const INTERVAL_TIME = 30000 // 30 seconds

export default function AutoSave({
  control,
  saveForm,
  formState: { isDirty, isSubmitting },
  initialData = {},
  isFormSubmitted,
  submitCode,
}) {
  // Local States _______________
  const [savedData, setSavedData] = React.useState({})
  // Form values ________________
  const watchedData = useWatch({ control, defaultValue: initialData })

  const dispatch = useDispatch()
  const isAiAnalyzing = useSelector(
    state => state.aiSyncedAnswers.isAiAnalyzing,
  )

  useMountEffect(() => {
    updateTheParSubmittedData()
  })

  React.useEffect(() => {
    setSavedData(initialData)
  }, [initialData])

  const isFormDirtyAfterLastSave = React.useMemo(() => {
    if (!equals(savedData, watchedData) && isDirty) {
      return true
    }
    return false
  }, [watchedData, savedData, isDirty])

  const updateTheParSubmittedData = draftCode => {
    saveForm(draftCode).then(res => {
      setSavedData((prevData = {}) => ({ ...prevData, ...res?.row?.data }))
      if (!draftCode || draftCode !== res?.row?.submit_code) {
        dispatch(setGeneralDataToSubmit({ submit_code: res?.row?.submit_code }))
      }
    })
  }
  const saveData = () => {
    if (
      isFormDirtyAfterLastSave &&
      !isSubmitting &&
      !isFormSubmitted &&
      !isAiAnalyzing
    ) {
      updateTheParSubmittedData(submitCode)
    }
  }

  useInterval(() => {
    saveData()
  }, INTERVAL_TIME)

  return <></>
}
